import { focusSafely } from '@react-aria/focus';
import Link from 'next/link';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { BottomBanner, BottomBannerSimple } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics';
import { useAppLoadedState, useComplianceLevelState, useUserState, useDeviceState } from '@/hooks/store';
import { REQUIRE_GDPR_MODAL } from '@/store/complianceLevel/types';
import { initAmplitude } from '@/utils/analytics/amplitude';
import { calmLogger } from '@/utils/calmLogger';
import { getCookie } from '@/utils/cookies';
import { postMe } from '@/utils/endpoints';
import { determineComplianceLevel } from '@/utils/getInitialState/initComplianceLevel';
import {
	ACCEPT_GDPR_COOKIES_KEY,
	DECLINE_GDPR_COOKIES_KEY,
	CALM_LIMITED_DATA_USE,
	acceptGdprCookies,
	acceptOptInCookies,
	declineGdprCookies,
	declineOptInCookies,
	removeAllCookies,
	isHomePageCookies,
} from '@/utils/privacyCookies';

import messages from './messages';

export interface CookiePreferencesBannerProps {
	id?: string;
	willForceShow?: boolean;
	willAnnounce?: boolean;
	className?: string;
	isOpen?: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
}
const CookiePreferencesBanner = ({
	id,
	willForceShow = false,
	willAnnounce = false,
	className,
	isOpen = false,
	setIsOpen = () => {},
}: CookiePreferencesBannerProps) => {
	const appLoaded = useAppLoadedState();
	const complianceLevel = useComplianceLevelState();
	const user = useUserState();
	const device = useDeviceState();
	const { formatMessage } = useIntl();
	const { logEventAsync, isAnalyticsInitialized } = useAnalytics();
	const ref = useRef<HTMLDivElement>(null);
	const requireGdprChoice = device.requires_gdpr_choice;
	const requireGdprModal = complianceLevel === REQUIRE_GDPR_MODAL;

	useEffect(() => {
		setIsOpen(willForceShow || appLoaded);
	}, [willForceShow, appLoaded, setIsOpen]);
	useEffect(() => {
		if (willAnnounce && ref.current) {
			focusSafely(ref.current);
		}
	}, [willAnnounce, ref]);

	// Handles automatically dismissing the simple banner if
	// user hasn't manually dismissed within 60 seconds.
	useEffect(() => {
		// Early out if handling GDPR (not simple banner)
		if (requireGdprModal) {
			return;
		}
		// Set up timer for 60 seconds
		const autoCloseTimer = setTimeout(() => {
			setIsOpen(false);
		}, 60000);
		return () => {
			clearTimeout(autoCloseTimer);
		};
	}, [requireGdprModal, setIsOpen]);

	// Handles user choice to accept the opt-in cookies
	const onAccept = async (): Promise<void> => {
		if (requireGdprChoice) {
			acceptGdprCookies();
		} else {
			acceptOptInCookies();
		}

		if (user) {
			// Need to update this for the post call these are all false since the user allows everything
			const newComplianceLevel = determineComplianceLevel({
				requiresGdpr: false,
				isLimitedData: false,
				hasDeclinedGdprCookies: false,
				isHipaaUser: user?.is_hipaa_compliant,
			});
			await postMe({
				has_opted_in_limited_data_use: false,
				compliance_level: newComplianceLevel,
			}).catch(error =>
				calmLogger.error(
					'Error in CookiePreferencesBanner postMe',
					{ has_opted_in_limited_data_use: false },
					error,
				),
			);
		}

		setIsOpen(false);
		try {
			if (!isAnalyticsInitialized) {
				await initAmplitude();
			}
			await logEventAsync({
				eventName: 'Cookie Preferences : Accept All',
			});
		} catch (error) {
			calmLogger.error('Error in CookiePreferencesBanner logEventAsync Accept All', {}, error);
		}
		window.location.reload();
	};

	// Handles user choice to decline the opt-in cookies
	const onDecline = async (): Promise<void> => {
		removeAllCookies();
		if (requireGdprChoice) {
			declineGdprCookies();
		} else {
			declineOptInCookies();
		}

		if (user) {
			const newComplianceLevel = determineComplianceLevel({
				requiresGdpr: !!requireGdprChoice,
				isLimitedData: !requireGdprChoice, // We only set a limited data cookie OR a GDPR cookie, not both
				hasDeclinedGdprCookies: !!requireGdprChoice,
				isHipaaUser: user?.is_hipaa_compliant,
			});
			await postMe({
				has_opted_in_limited_data_use: true,
				compliance_level: newComplianceLevel,
			}).catch(error =>
				calmLogger.error(
					'Error in CookiePreferencesBanner postMe',
					{ has_opted_in_limited_data_use: true },
					error,
				),
			);
		}

		setIsOpen(false);
		try {
			if (!isAnalyticsInitialized) {
				await initAmplitude();
			}
			await logEventAsync({
				eventName: 'Cookie Preferences : Essential Only',
			});
		} catch (error) {
			calmLogger.error('Error in CookiePreferencesBanner logEventAsync Essential Only', {}, error);
		}
		window.location.reload();
	};

	// Handles user choice to simply dismiss the banner
	const onDismiss = async (): Promise<void> => {
		setIsOpen(false);
		try {
			await logEventAsync({
				eventName: 'Cookie Preferences : Dismiss',
			});
		} catch (error) {
			calmLogger.error('Error in CookiePreferencesBanner logEventAsync Dismiss', {}, error);
		}
	};

	// Checks if the user has set any cookie preferences
	const hasUserSetCookiePreferences = (): boolean => {
		const hasAcceptGDPRCookiesTrue = Boolean(getCookie(ACCEPT_GDPR_COOKIES_KEY)) === true;
		const hasDeclineGDPRCookiesTrue = Boolean(getCookie(DECLINE_GDPR_COOKIES_KEY)) === true;
		const hasCalmLimitedDataUseCookieTrue = Boolean(getCookie(CALM_LIMITED_DATA_USE)) === true;
		if (hasAcceptGDPRCookiesTrue || hasDeclineGDPRCookiesTrue || hasCalmLimitedDataUseCookieTrue) {
			return true;
		}
		return false;
	};

	// If being forced to show or if GDPR is required, we need to show
	// the original cookie preferences UI with accept+decline buttons.
	const showOriginalCookiePreferences = Boolean(willForceShow || requireGdprModal);
	if (showOriginalCookiePreferences) {
		return (
			<BottomBanner
				id={id}
				title={formatMessage(messages.title)}
				isOpen={isOpen}
				acceptText={formatMessage(messages.acceptAll)}
				declineText={formatMessage(messages.essentialOnly)}
				onAccept={onAccept}
				onDecline={onDecline}
				innerRef={ref}
				className={className}
			>
				{formatMessage(messages.body, {
					cookiePolicy: <Link href="/cookies">{formatMessage(messages.cookiePolicy)}</Link>,
				})}
			</BottomBanner>
		);
	}

	// If GDPR is not required, and not showing the original cookie
	// preferences UI and on the root (homepage), if the user hasn't set
	// any cookie preferences, we need to show the simple cookie banner.
	const showSimpleCookieBanner =
		requireGdprModal === false &&
		showOriginalCookiePreferences === false &&
		isHomePageCookies(window.location.pathname) &&
		hasUserSetCookiePreferences() === false;
	if (showSimpleCookieBanner) {
		return (
			<BottomBannerSimple
				id={id}
				title={formatMessage(messages.title)}
				isOpen={isOpen}
				onDismiss={onDismiss}
				innerRef={ref}
				className={className}
			>
				{formatMessage(messages.bodyNonGDPR, {
					privacyPolicy: <Link href="/privacy-policy">{formatMessage(messages.privacyPolicy)}</Link>,
				})}
			</BottomBannerSimple>
		);
	}

	return null;
};
export default CookiePreferencesBanner;
