import Head from 'next/head';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import SkipToMain, { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import {
	CookiePreferencesBannerContext,
	COOKIE_PREFERENCES_BANNER_ID,
} from '@/components/app/layout/CookiePreferencesBannerProvider';
import { OfferBannerContext } from '@/components/app/layout/OfferBannerProvider';
import DevToolsOverlay from '@/components/devtools/DevToolsOverlay';
import Gear from '@/components/devtools/Gear';
import messages from '@/components/layout/LayoutWrapper/messages';
import RecaptchaForm from '@/components/recaptcha/RecaptchaForm';
import recaptchaMessages from '@/components/recaptcha/RecaptchaForm/messages';
import ToastNotification from '@/components/ui/ToastNotification';
import { useAnalytics } from '@/hooks/analytics';
import {
	useDeviceState,
	useUserState,
	useRecaptchaVisibleState,
	useComplianceLevelState,
	useThemeState,
} from '@/hooks/store';
import { NO_RESTRICTIONS } from '@/store/complianceLevel/types';
import { calmLogger } from '@/utils/calmLogger';
import { getCookie } from '@/utils/cookies';
import getUrl from '@/utils/getUrl';
import { checkGPC } from '@/utils/gpc';
import { CALM_USER_ID } from '@/utils/privacyCookies';

import CookiePreferencesBanner from '../CookiePreferencesBanner';
import LayoutScripts from '../LayoutScripts';
import { LayoutProps, SeoProps } from './types';
import { getIsMobileClient } from './utils';

const Layout = ({
	children,
	pageName,
	customSeo,
	title = 'Calm - The #1 App for Meditation and Sleep',
	hideAcceptCookiesModal = false,
	customMainId = `#${MAIN_CONTENT_ID}`,
}: LayoutProps) => {
	const router = useRouter();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const complianceLevel = useComplianceLevelState();
	const recaptchaVisible = useRecaptchaVisibleState();
	const user = useUserState();
	const device = useDeviceState();
	const theme = useThemeState();
	const { canBeShown, isAnnounced, isForciblyShown, setCanBeShown, setIsOpen, isOpen } = useContext(
		CookiePreferencesBannerContext,
	);
	const { bannerComponent } = useContext(OfferBannerContext);

	const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

	const setupSeo = (): SeoProps => {
		return {
			title: customSeo?.title ?? formatMessage(messages.homepageTitle),
			primaryDescription: customSeo?.primaryDescription ?? formatMessage(messages.homepagePrimaryDescription),
			description: customSeo?.description ?? formatMessage(messages.homepageDescription),
			imageUrl: customSeo?.imageUrl ?? `${process.env.NEXT_PUBLIC_WWW_URL}/_n/images/social/calm-meta.png`,
			url: customSeo?.url ?? `${getUrl('www') as string}${router.asPath}`,
		};
	};

	const analyticsPageName = pageName || router.pathname;
	const userId = user?.id ?? getCookie(CALM_USER_ID);
	useEffect(() => {
		logEvent({
			eventName: 'Page : Viewed',
			eventProps: {
				page_name: analyticsPageName,
				calm_user_id: userId || null,
			},
		});
	}, [logEvent, analyticsPageName, userId]);

	useEffect(() => {
		setCanBeShown(!hideAcceptCookiesModal);
	}, [hideAcceptCookiesModal, setCanBeShown]);

	useEffect(() => {
		const checkGPCWrapper = async () => {
			await checkGPC(device, user, logEvent);
		};
		checkGPCWrapper().catch(error => calmLogger.error('Error in LayoutWrapper checkGPCWrapper', {}, error));
	}, [device, logEvent, user]);

	const cookieBanner = useMemo(
		() =>
			canBeShown && (
				<CookiePreferencesBanner
					id={COOKIE_PREFERENCES_BANNER_ID}
					willForceShow={isForciblyShown}
					willAnnounce={isAnnounced}
					setIsOpen={setIsOpen}
					isOpen={isOpen}
				/>
			),
		[isAnnounced, isForciblyShown, canBeShown, isOpen, setIsOpen],
	);

	const isMobileClient = getIsMobileClient(router.query);
	const showSkipToMain = !isMobileClient && !theme.webAppLayout;

	return (
		<>
			<Head>
				<meta property="calm:repo" content="monorepo" data-testid="monorepo" />
				{complianceLevel === NO_RESTRICTIONS && (
					<>
						<meta property="fb:app_id" content="1592594007691679" />
						<meta name="p:domain_verify" content="3197272a4fe2efb4c260cf3df62be451" />
					</>
				)}
				<meta name="Description" content={setupSeo().primaryDescription} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={setupSeo().title} />
				<meta property="og:image" content={setupSeo().imageUrl} />
				<meta property="og:url" content={setupSeo().url} />
				<meta property="og:description" content={setupSeo().description} />
				<meta property="twitter:site" content="@calm" />
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:title" content={setupSeo().title} />
				<meta property="twitter:image" content={`${setupSeo().imageUrl}`} />
				<meta property="twitter:description" content={setupSeo().description} />
				<title>{title}</title>
			</Head>
			<LayoutScripts />
			{showSkipToMain && <SkipToMain href={customMainId} />}
			{bannerComponent && bannerComponent}
			{children}
			{cookieBanner}
			{recaptchaVisible && (
				<Modal
					canClose={false}
					isOpen={recaptchaVisible}
					closeModal={(): null => null}
					aria-label={formatMessage(recaptchaMessages.title)}
				>
					<RecaptchaForm />
				</Modal>
			)}
			<ToastNotification />
			{process.env.NEXT_PUBLIC_CALM_ENV !== 'production' && process.env.NODE_ENV !== 'test' && (
				<>
					<Gear setIsDevToolsOpen={setIsDevToolsOpen} />
					{isDevToolsOpen && <DevToolsOverlay setIsDevToolsOpen={setIsDevToolsOpen} />}
				</>
			)}
		</>
	);
};

export default Layout;
